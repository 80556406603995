.chart-main-stats {
    display: flex;
    height: 400px;
}

.chart-bar {
    display: flex;
    flex-direction: column;
    height: 500px;
}

.chart-bar > .chart-content {
    height: 400px;
}

.chart-bar > .chart-second-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.chart-bar > .chart-second-menu > .chart-menu__el {
    color: white;
}

.chart-money > .chart-second-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.chart-money  > .chart-second-menu > .chart-menu__el {
    color: white;
}

.chart-livedead {
    display: flex;
    flex-direction: column;
    height: 500px;
}

.chart-livedead > .chart-content {
    height: 400px;
}

.chart-livedead > .chart-second-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.chart-livedead > .chart-second-menu > .chart-menu__el {
    color: white;
}


.chart-line > .chart-content {
    height: 400px;
}

.chart-line > .chart-second-menu {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.chart-line > .chart-second-menu > .chart-menu__el {
    color: white;
}

.chart__container {
    width: 25%;
}

.chart-menu {
    display: flex;
    justify-content: center;
    margin: 25px 0;
}

.chart-menu__el {
    background-color: skyblue;
    padding: 7px;
    border-radius: 4px;
    margin-left: 25px;
    cursor: pointer;
    transition: 0.2s;
}

.chart-menu__el:hover {
    opacity: .7;
    transition: 0.2s;
}

.chart-menu__el:nth-child(1) {
    margin-left: 0px !important;
}

.chart-menu__el > i {
    font-size: 25px;
    text-align: center;
    color: white;
}


.anchor-cloud {
    display: flex;
    flex-wrap: wrap;
}

.anchor-cloud > div {
    background-color: skyblue;
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 25px;
    margin: 15px;
    cursor: pointer;
}

.anchor-cloud-button{
    background-color: salmon;
    color: white;
    padding: 5px 15px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    border-radius: 30px;
    cursor: pointer;
}

.filter {
    font-size: 16px;
    padding: 15px 0px 15px 10px;
    color: green;
}
.filter > span {
    font-weight: bold;
    color: black;
}
.filter > i {
    color: black;
    cursor: pointer;
}

.chart-menu__el-active{
    background-color: silver !important;
    cursor: default !important;
}